@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    input[type='number'].appearance-none::-webkit-outer-spin-button,
    input[type='number'].appearance-none::-webkit-inner-spin-button,
    input[type='number'].appearance-none {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield !important;   
    }
    
    .showListStyles {
        ul {
            list-style: disc inside;
            margin-left: 1rem;
        }
        ol {
            list-style: decimal inside;
            margin-left: 1rem;
        }
    }
    .showStyledLinks {
        a {
            text-decoration: underline;
            color: theme('colors.cyan.DEFAULT')
          
        }
    }
}

@mixin scrollbars(
        $size: 0.75rem,
        $foreground-color: #7DA0B2,
        $background-color: #E2ECF2
    ) 
{
    // For Chrome & Safari
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }
    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
        border-radius: $size;
    }
    &::-webkit-scrollbar-track {
        background: $background-color;
    }

    // Standard version (Firefox only for now)
    scrollbar-color: $foreground-color $background-color;
    scrollbar-width: $size;
}

@mixin tableGrid(
    $col1: 40%,
    $col2: 25%,
    $col3: 15%,
    $col4: 20%,
    $col5: 0%,
) {
    width: 100%;
    display: grid;
    grid-template-columns: [col-1] $col1 [col-2] $col2 [col-3] $col3 [col-4] $col4 [col-5] $col5;

    >div {
        &.start-1 {
            grid-column-start: 1;
        }
        &.start-2 {
            grid-column-start: 2;
        }
        &.start-3 {
            grid-column-start: 3;
        }
        &.start-4 {
            grid-column-start: 4;
        }
        &.start-5 {
            grid-column-start: 5;
        }
    }
}

html {
    @include scrollbars(1.1rem, #7DA0B2, #E2ECF2);

    .customScrollbars {
        @include scrollbars;
    }

    .customScrollbarsLight {
        @include scrollbars($background-color: #0000);
    }

    .background-gradient {
        background: linear-gradient(90deg, #F9FBFC 0%, #ffffff00 13.7%);
        background-blend-mode: multiply;
    }

    .hiddenScrollbars {
        /* Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .datasheetTableGrid {
        @include tableGrid(30%, 13%, 25%, 15%, 17%);
    }

    .marketingTableGrid {
        @include tableGrid;
    }

    .certificatesTableGrid {
        @include tableGrid(40%, 30%, 10%, 20%);
    }
}


