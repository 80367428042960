.marketingList {
    .background-gradient {
        background: linear-gradient(90deg, #F9FBFC 0%, #FFFFFF 13.7%);
        background-blend-mode: multiply;
    }
}

.marketingGridContainer {
    width: 100%;
    display: grid;
    grid-template-columns: [col-1] 40% [col-2] 25% [col-3] 15% [col-4] 20% ;

    >div {
        &.start-1 {
            grid-column-start: 1;
        }
        &.start-2 {
            grid-column-start: 2;
        }
        &.start-3 {
            grid-column-start: 3;
        }
        &.start-4 {
            grid-column-start: 4;
        }
       

    }
}