.regulatoryInfos {
    

    .infoRow {
        &:not(:last-child){
            border-bottom: solid #E2ECF2 1px;
        }

        &.ghs-row {
            img {
                height: 5rem;
                margin: 1rem .5rem;
            }
        }
    }
}