.datasheetDisclosure{
    .subListItem:last-child {
        position: relative;
        &::after{
            content: '';
            position: absolute;
            height: calc(50% - 1px);
            border: 1px solid white;
            bottom: 0px;
            left: -1px;
            width: 1px;
        }
    }
}