.certificateList {
    .background-gradient {
        background: linear-gradient(90deg, #F9FBFC 0%, #FFFFFF 13.7%);
        background-blend-mode: multiply;
    }
}

.certificatesGridContainer {
    width: 100%;
    display: grid;
    grid-template-columns: [col-1] 40% [col-2] 30% [col-3] 10% [col-4] 20%;

    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */

    letter-spacing: 0.02em;

    /* Grey/Grey-6 */

    color: #5A7C8D;

    >div {
        &.start-1 {
            grid-column-start: 1;
        }
        &.start-2 {
            grid-column-start: 2;
        }
        &.start-3 {
            grid-column-start: 3;
        }
        &.start-4 {
            grid-column-start: 4;
        }
       

    }
}