.defaultDocTypeEntry {
    .subListItem:last-child {
        position: relative;
        &::after{
            content: '';
            position: absolute;
            height: 50%;
            border: 1px solid white;
            bottom: -1px;
            left: -1px;
            width: 1px;
        }
    }
}